<template>
  <BaseAlertTemplate
    v-if="loaded"
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'
import alertModelHelper from '../../../../../models/Helpers/alertModelHelper'
import _ from 'lodash'
import { getAutoplugRuleInfo } from '@/components/AlertingComponents/Sheet/SheetFragments/AlertTypeTemplate/TemplateCommonLogic/commonLogicAutoplugAlert'
import AlertModel from '@/models/Alertings/AlertModel'

export default {
  name: 'BaseBrief',
  props: {
    alert: {
      type: AlertModel
    },
    // @type {CheckPreMepResult}
    checkPreMepResult: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Link to surcouche',
          value: (alert) => {
            let link = alertModelHelper.generateDataFormLinkToSurcouche(alert, this.$_FRONT_HOST)
            return `<a target="_blank" rel="noopener noreferrer" href="${link}">link to surcouche</a>`
          }
        },
        { label: 'Username', value: 'data.username' },
        {
          label: 'Brief',
          value: (alert) => {
            if (_.isObject(alert.data.brief)) {
              return `<span>${this.generateBriefContent(this.alert, this.checkPreMepResult)}</span>`
            }

            if (!alert.data.brief) {
              return ''
            }
            const briefContent = alert.data.brief.replace(/\\n/g, '<br>')
            return `<span>${this.$commonUtils.htmlEntities(briefContent)}</span>`
          }
        },
        { label: 'Name', value: 'data.group_name' },
        { label: 'Brief Id', value: 'data.new_brief_id' }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ],
      loaded: false
    }
  },
  created: function () {

  },
  mounted: function () {
    this.setAdditionalDetailedDataFields()
  },
  methods: {
    async setBulkAddData (bulkAddId) {
      this.detailedData.push({ label: 'Bulk Add ID', value: 'data.bulk_add_id' })
      let response = null
      response = await this.$apiCaller.getOverviewsWithSearch({ bulkAddId: bulkAddId, onlyIds: true })
      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling api getOverviewsWithSearch')
        this.$store.commit('setErrorMessageWithResponse', response)
        this.alert.data['overviews'] = []
      } else {
        this.alert.data['overviews'] = response.data != null ? response.data.join(', ') : []
      }
      this.detailedData.push({ label: 'Insertion Order IDs', value: 'data.overviews' })
    },
    async setAdditionalDetailedDataFields () {
      // Bulk add or simple overview
      const bulkAddId = this.alert.data.bulk_add_id
      if (bulkAddId != null) {
        await this.setBulkAddData(bulkAddId)
      } else {
        this.detailedData.push({ label: 'Overview Id', value: 'data.overview_id' })
      }
      // Autoplug
      if (this.alert.data.autoplug_name && this.alert.data.autoplug_id) {
        this.detailedData.push({
          label: 'Autoplug rule',
          value: (alert) => {
            return getAutoplugRuleInfo(alert)
          }
        })
      }
      this.loaded = true
    },
    tempBriefContent (key, value) {
      let displayedValues = this.$commonUtils.htmlEntities(value)
      if (_.isObject(value)) {
        displayedValues = JSON.stringify(value, null, 4)
      }
      return `<li><strong>${this.$commonUtils.htmlEntities(key)}</strong>: ${displayedValues}</li>`
    },
    /**
     * Generate brief info
     * @param {AlertModel} alert
     * @param {CheckPreMepResult} checkPreMepResult
     */
    generateBriefContent (alert, checkPreMepResult) {
      let content = '<ul>'
      const excludedFields = [
        'alert_brief_send',
        'brief_type',
        'new_brief_id',
        'budget_to_saturate_in_priority',
        'constraints_allow_completion_rate',
        'constraints_allow_frequency_cap',
        'constraints_allow_unknown_demographic',
        'constraints_allow_view_rate',
        'constraints_automatically_remove_deprecated_url',
        'constraints_budget_repartition',
        'constraints_completion_rate',
        'constraints_creative_activity',
        'constraints_fold_position',
        'constraints_overwrite_bid_adjustments_on_device',
        'constraints_time_parting',
        'constraints_viewability_settings',
        'contact_mail_copilot',
        'conversion_measurement_tool',
        'currency',
        'is_template',
        'line_item_budget_restrictions',
        'revenue_type',
        'saved_template_brief',
        'status',
        'swid',
        'text',
        'overview_id',
        'from_template_brief_id'
      ]

      /**
       * Some key of the brief should be sorted in a specific order
       * @type {string[]}
       */
      const sortPrioritiesKey = [
        'constraints_overwrite_frequency_mode',
        'constraints_frequency_cap_exposure',
        'constraints_frequency_cap_amount',
        'constraints_frequency_cap_period',
        'constraints_allow_frequency_target',
        'constraints_frequency_target_exposure',
        'constraints_frequency_target_amount',
        'constraints_frequency_target_period'
      ]

      const dsp = alert.getDspName()

      if (dsp === 'youtube') {
        excludedFields.push('allow_frequency_target')
      }

      const dontDisplayIfNill = ['funnel']
      content += this.tempBriefContent('Check premep status', (checkPreMepResult ? checkPreMepResult.check_setup_state : 'NC'))

      const entries = Object.entries(alert.data.brief)

      const sortedEntries = entries.sort((a, b) => {
        const [keyA, valueA] = a
        const [keyB, valueB] = b
        const indexA = sortPrioritiesKey.indexOf(keyA)
        const indexB = sortPrioritiesKey.indexOf(keyB)
        if (indexA === -1 && indexB === -1) {
          return 0
        }
        if (indexA === -1) {
          return 1
        }
        if (indexB === -1) {
          return -1
        }
        return indexA - indexB
      })

      sortedEntries.forEach((entry) => {
        const [key, value] = entry
        if (excludedFields.includes(key)) {
          return
        }

        if (dontDisplayIfNill.includes(key) && (value == null || (_.isObject(value) && _.isEmpty(value)))) {
          return
        }

        if (value) {
          content += this.tempBriefContent(key, value)
        }
      })
      content += '</ul>'
      return content
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
